import React from 'react';

const TableImage = props => {
  const { width, className } = props;
  return (
    <svg id="Layer_1" width={width} className={className} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 581.67 141.91">
      <defs></defs>
      <g>
        <polygon className="table-1" points="141.35 21.49 86.89 135.5 74.48 135.5 94.01 86.44 94.03 86.4 94.84 84.36 124.82 21.49 141.35 21.49" />
        <polygon className="table-2" points="125.15 15.31 125 15.59 124.36 16.72 121.66 21.49 84.86 86.4 85.83 84.36 121.62 21.49 124.33 16.72 125.14 15.31 125.15 15.31" />
        <polygon className="table-3" points="459.79 21.49 124.32 21.49 126.59 16.01 457.08 16.01 459.79 21.49" />
        <polygon className="table-1" points="506.99 135.5 494.58 135.5 440.07 21.49 456.61 21.49 486.61 84.36 487.42 86.4 487.45 86.44 506.99 135.5" />
        <polygon className="table-3" points="581.67 12.91 477.17 12.91 526 141.91 510.77 141.91 487.45 90.28 487.42 90.24 486.61 88.09 456.61 21.93 454.33 16.91 453.92 16.01 457.08 16.01 456.28 14.86 456.26 14.87 455.41 13.66 454.89 12.91 452.5 12.91 452.94 13.88 453.64 15.44 453.66 15.43 453.92 16.01 127.51 16.01 127.77 15.43 127.78 15.43 128.48 13.89 128.92 12.91 127.74 12.91 127.3 13.66 126.6 14.86 126.59 14.86 125.91 16.01 127.51 16.01 127.09 16.92 124.82 21.94 94.84 88.1 94.03 90.24 94.01 90.29 70.72 141.91 55.5 141.91 104.29 12.91 0 12.91 0 5.39 10 0 571.67 0 581.67 5.39 581.67 12.91" />
        <polygon className="table-3" points="128.92 12.91 128.48 13.89 127.78 15.43 127.77 15.43 127.51 16.01 125.91 16.01 126.59 14.86 126.6 14.86 127.3 13.66 127.74 12.91 128.92 12.91" />
        <polygon className="table-3" points="457.08 16.01 453.92 16.01 453.66 15.43 453.64 15.44 452.94 13.88 452.5 12.91 454.89 12.91 455.41 13.66 456.26 14.87 456.28 14.86 457.08 16.01" />
        <polygon className="table-2" points="496.55 86.4 459.75 21.49 457.05 16.72 456.41 15.59 456.25 15.32 456.28 15.31 457.08 16.72 459.79 21.49 495.58 84.36 496.55 86.4" />
        <polygon className="table-4" points="581.67 5.39 0 5.39 10 0 571.67 0 581.67 5.39" />
        <polygon className="table-5" points="457.08 16.01 125.91 16.01 126.59 14.86 126.6 14.86 127.3 13.66 127.74 12.91 454.89 12.91 455.41 13.66 456.26 14.87 456.28 14.86 457.08 16.01" />
        <polygon className="table-2" points="128.92 12.91 128.48 13.89 127.78 15.43 127.77 15.43 127.51 16.01 125.91 16.01 126.59 14.86 126.6 14.86 127.3 13.66 127.74 12.91 128.92 12.91" />
        <polygon className="table-2" points="457.08 16.01 453.92 16.01 453.66 15.43 453.64 15.44 452.94 13.88 452.5 12.91 454.89 12.91 455.41 13.66 456.26 14.87 456.28 14.86 457.08 16.01" />
        <polygon className="table-2" points="128.92 12.91 128.48 13.89 127.78 15.43 127.77 15.43 127.09 16.92 124.82 21.94 94.84 88.1 94.03 90.24 94.01 90.29 70.72 141.91 55.5 141.91 104.29 12.91 128.92 12.91" />
        <polygon className="table-2" points="526 141.91 510.77 141.91 487.45 90.28 487.42 90.24 486.61 88.09 456.61 21.93 454.33 16.91 453.66 15.43 453.64 15.44 452.94 13.88 452.5 12.91 477.17 12.91 526 141.91" />
      </g>
    </svg>
  )
}

export default TableImage;
