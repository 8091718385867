import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Laptop from './Laptop';
import SpeechBubble from './SpeechBubble';

const useStyles = makeStyles({
  person: {
    width: '22%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      '& .speechBubble-left, & .speechBubble-right': {
        display: 'block !important'
      }
    }
  },
  laptop: {
    position: 'absolute',
    width: '11.5%',
    top: '30%',
    zIndex: 2,
  }
})

const Person = props => {
  const classes = useStyles();
  const [ typed, setTyped ] = useState();
  const { hair, skin, chair, shirt, pants, shoes, logo, speech, speechPosition } = props;
  const { headAnimation, eyesAnimation, mouthAnimation } = props

  const resetSpeechBubble = () => {
    typed.reset();
  }

  return (
    <div id="person" className={classes.person} onMouseEnter={resetSpeechBubble}>
      <SpeechBubble setTyped={setTyped} speech={speech} speechPosition={speechPosition} />
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.23 259.56">
        <defs></defs>
        <g>
          <g>
            <g>
              <path className={`${chair}-1`} d="M227.46,200.19l-20-.09c-5.86,0-8.65-2.73-8.65-8.47v-65c0-11.66,8.13-19.64,20-19.65h39.07c9.23,0,15.31,6,15.31,15v70.49c0,5-2.67,7.64-7.72,7.67l-19.48.08Z" transform="translate(-182.49 -63.87)" />
              <path className={`${chair}-2`} d="M229,239.8" transform="translate(-182.49 -63.87)" />
              <path className={`${chair}-3`} d="M286.75,310.94a5.37,5.37,0,0,1-7.56.75,4.64,4.64,0,0,1-.87-.9,8.13,8.13,0,0,1-.1-10.09c1.6-2.1,1.58-2.29-.66-3.26a96.38,96.38,0,0,0-24.84-6.7,92.32,92.32,0,0,0-10.63-1c-1.28,0-1.89.44-1.82,2.07s.06,3.35,0,5a5,5,0,0,0,1.25,3.82,7.83,7.83,0,0,1,1.19,8c-1,2.64-3.28,4.45-5.63,4.26a5.87,5.87,0,0,1-5.35-4.4c-.9-2.73-.74-5.49,1-7.65a5.56,5.56,0,0,0,1.34-4.26c-.06-1,0-2,0-3,0-3.91,0-4-3.18-3.82-11.66.74-23.11,2.88-34.14,7.65-2.25,1-2.25,1-.57,3.17a8.4,8.4,0,0,1-.07,10.12,5.36,5.36,0,0,1-7.51,1,5.29,5.29,0,0,1-.78-.72,8.19,8.19,0,0,1-.56-10.06,6.28,6.28,0,0,0,1.47-4.44,4.61,4.61,0,0,1,3-4.69,99.91,99.91,0,0,1,27.84-8.26,125.84,125.84,0,0,1,12.72-1.22c1.66-.1,1.75-1.15,1.79-2.68,0-1.69-.48-2.25-1.89-2.25-3.24,0-3.24-.08-3.24-3.89V227.8h16.51v46.48c0,3.18,0,3.28-2.72,3.16-1.69-.07-2.6.29-2.47,2.7.09,1.72.63,2.16,1.95,2.23a113.5,113.5,0,0,1,32.83,6.27,74.11,74.11,0,0,1,8.24,3.55,4.24,4.24,0,0,1,2.38,4.43,5.33,5.33,0,0,0,1.27,4A8.08,8.08,0,0,1,286.75,310.94Z" transform="translate(-182.49 -63.87)" />
              <path className={`${chair}-1`} d="M277,224.93l-.15.18a7.22,7.22,0,0,1-6,2.65c-8.47-.07-16.94,0-25.41.07H229c-9.14,0-18.27-.11-27.4,0a7.13,7.13,0,0,1-6-2.71l-.12-.15a10.27,10.27,0,0,1-.82-7.83,9.79,9.79,0,0,1,1.3-2.08,7,7,0,0,1,5.83-2.44c8.57.1,17.15,0,25.72,0h18.62c8.22,0,16.44.13,24.65,0a6.87,6.87,0,0,1,5.8,2.48,9.59,9.59,0,0,1,1.27,2.07A10.23,10.23,0,0,1,277,224.93Z" transform="translate(-182.49 -63.87)" />
              <path className={`${chair}-3`} d="M246.08,212.53H227.43V200.2H246Q246.07,206.35,246.08,212.53Z" transform="translate(-182.49 -63.87)" />
            </g>
            <g>
              <g id="Page-1">
                <g id="Desktop-HD-Copy">
                  <g id="Group-6">
                    <polygon id="Fill-1" className={`${shirt}-1`} points="24.71 55.55 27.7 86.63 29.73 144.54 75.99 144.54 78.3 86.63 81.02 55.55 24.71 55.55" />
                    <path id="Fill-2" className={`${skin}-2`} d="M244,122.52s-2.57,8.51-8.66,8.45c-5.84-.06-7.83-8.45-7.83-8.45V107H244Z" transform="translate(-182.49 -63.87)" />
                    <path id="Fill-12" className={`${shirt}-1`} d="M207.2,119.41s-21.67,43.18-23.75,48a15.91,15.91,0,0,0-.79,3.18,8.73,8.73,0,0,0,6.84,10.28h0a8.42,8.42,0,0,0,1.74.17l14.54,1s5.49.46,5.89-6.8a11.68,11.68,0,0,0-2.77-8.31l-7-1.89,8.65-21.24s-3-24.43-3.32-24.43" transform="translate(-182.49 -63.87)" />
                    <path id="Fill-14" className={`${shirt}-1`} d="M197,177.7c0,3.82,4.54,3.71,8.27,3.71s6.24-3.26,6.24-7.08-3.18-8.11-6.41-6.24c-4.39,2.53-8.1,7.25-8.1,9.61" transform="translate(-182.49 -63.87)" />
                    <path id="Fill-16" className={`${skin}-2`} d="M211,171.73a8.27,8.27,0,0,0-1.24-2.3,5.3,5.3,0,0,0-1.93-.68,28.86,28.86,0,0,0-3.2-.41c-4.2,2.58-7.68,7.08-7.68,9.36a3.15,3.15,0,0,0,.57,2c1.19.67,3.23-1.78,5-2,2.36-.22,3.45-.25,5.73-.5,1.51-1.71,2.74-3.79,2.75-5.47" transform="translate(-182.49 -63.87)" />
                    <g id="Group-4">
                      <g>
                        <g id="Fill-22">
                          <path className={`${skin}-2`} d="M235.35,107V131h0c6.09.06,8.66-8.45,8.66-8.45V107Z" transform="translate(-182.49 -63.87)" />
                        </g>
                        <path id="Fill-24" className={`${skin}-4`} d="M235.49,106.92v9.5a18.47,18.47,0,0,0,8.67-1.91v-7.59Z" transform="translate(-182.49 -63.87)" />
                        <path id="Fill-24-Copy" className={`${skin}-4`} d="M236.15,106.92v9.5a18.47,18.47,0,0,1-8.67-1.91v-7.59Z" transform="translate(-182.49 -63.87)" />
                      </g>
                    </g>
                    <path id="Fill-35" className={`${shirt}-1`} d="M263.6,119.41s21.66,43.18,23.75,48a14.63,14.63,0,0,1,.78,3.18,8.73,8.73,0,0,1-6.84,10.28h0a8.39,8.39,0,0,1-1.73.17L265,182s-5.49.46-5.89-6.8a11.68,11.68,0,0,1,2.77-8.31l7-1.89-8.66-21.24s3-24.43,3.33-24.43" transform="translate(-182.49 -63.87)" />
                    <path id="Fill-37" className={`${shirt}-1`} d="M273.86,177.7c0,3.82-4.54,3.71-8.27,3.71s-6.24-3.26-6.24-7.08,3.18-8.11,6.41-6.24c4.39,2.53,8.1,7.25,8.1,9.61" transform="translate(-182.49 -63.87)" />
                    <path id="Fill-39" className={`${skin}-2`} d="M259.81,171.73a7.89,7.89,0,0,1,1.24-2.3,5.3,5.3,0,0,1,1.93-.68,28.56,28.56,0,0,1,3.2-.41c4.2,2.58,7.68,7.08,7.68,9.36a3.15,3.15,0,0,1-.57,2c-1.19.67-3.23-1.78-5-2-2.36-.22-3.45-.25-5.74-.5-1.5-1.71-2.73-3.79-2.74-5.47" transform="translate(-182.49 -63.87)" />
                  </g>
                </g>
              </g>
              <g id="Page-1-2">
                <g id="Desktop-HD-Copy-2">
                  <g id={headAnimation} className="">
                    <path className={`${skin}-1`} d="M253,97.68c-1.77,1.52-2.67,1.22-3.09.15-.21,1.73-.78,4.81-1,6.22-.19,1.18-5.06,7-5.8,8-1.12,1.4-2,2.89-6.32,2.89h-1.93c-4.31,0-5.19-1.49-6.29-2.89-.75-1-5.61-6.82-5.8-8-.23-1.41-.79-4.49-1-6.22-.42,1.07-1.31,1.37-3.09-.15S216,90.92,218,89.34v-.06a3.41,3.41,0,0,1,2.91.82c-.16-1.6-.3-3.13-.41-4.48a29.58,29.58,0,0,1,.67-10.56c1.78-7.32,7-9,14.43-9h.4c7.45,0,12.68,1.68,14.47,9a29.34,29.34,0,0,1,.65,10.6c-.11,1.35-.25,2.88-.41,4.48a3.45,3.45,0,0,1,3-.86h0C255.73,90.88,254.77,96.16,253,97.68Z" transform="translate(-182.49 -63.87)" />
                    <path id="Fill-26-Copy" className={`${skin}-2`} d="M218,89.28a3.41,3.41,0,0,1,2.91.82c-.16-1.6-.3-3.13-.41-4.48a29.58,29.58,0,0,1,.67-10.56c1.78-7.32,7-9,14.43-9h.26V115h-1c-4.31,0-5.19-1.49-6.29-2.89-.75-1-5.61-6.82-5.8-8-.23-1.41-.79-4.49-1-6.22-.42,1.07-1.31,1.37-3.09-.15S216,90.9,218,89.28" transform="translate(-182.49 -63.87)" />
                    <path id="Combined-Shape" className={`${skin}-3`} d="M236.72,101.28l1.1-.37-2,1.79h0l-2-1.79,1.09.37h0l.91.31Z" transform="translate(-182.49 -63.87)" />
                    <path id="Fill-8" className={`${hair}-1`} d="M223.6,90.75s-2.1-.17-2.61-1.47c-1-2.3-1.12-6.67-1.12-8.62,0-4.29-.19-9.74,2.91-13,3.76-4,10.21-3.76,15.43-3.76a12.85,12.85,0,0,1,6,1,13,13,0,0,1,5.89,5.63c2.14,3.62,1.49,7.67,1.67,11.69.16,3.47.56,7.07-3.18,8.49-.58-1.65,0-4.14,0-5.89a14,14,0,0,0-.77-5.63c-1.35-3-3.46-2-6-1.31a30.09,30.09,0,0,1-11.83.86c-2.12-.32-5.35-2-6.32.79-.64,1.87-.11,4.41-.15,6.36,0,.86.14,4.84.14,4.84" transform="translate(-182.49 -63.87)" />
                  </g>
                </g>
              </g>
              <g>
                <rect className={`${skin}-2`} x="34.7" y="236.25" width="9.31" height="2.65" />
                <rect className={`${skin}-2`} x="65.63" y="236.25" width="9.31" height="2.65" />
                <path className={`${pants}-1`} d="M264.25,256a8.63,8.63,0,0,0-.1,1.12c-1.29,10.73-3,21.39-4.46,32.09-.13.94-1.24,11.1-1.34,11.1H247c-.06-3.22-.1-6.44-.15-9.66-.16-11.38-.31-22.77-.33-34.15,0-7,.42-13.91.09-20.86v-.17q-.1-2.13-.27-4.26a17.28,17.28,0,0,1-.3-2.49l-.12.12c.18-.2-.64-2.08-.75-2.36a21.71,21.71,0,0,0-1.17-2.37,8.67,8.67,0,0,0-3.21-3.45,6.91,6.91,0,0,0-9.06,1.19,6.17,6.17,0,0,0-1.65,3.31c-1.12,5.36-.71,10.88-.61,16.32.1,5.69.08,11.39,0,17.08-.14,9.77-.56,19.54-1.09,29.3,0,.91-.48,12.45-1,12.45H216.5c-.55-3-1-6-1.49-9q-1.72-11.16-3-22.43c-1-9.59-1.82-19.22-2.15-28.85-.13-4.27-.14-8.54-.1-12.81a.54.54,0,0,1,0-.18,123.29,123.29,0,0,1,2.31-23.15c15.45,2.94,31.69,5,46.64,0,5.61,8,6.29,18.64,6.59,28.2A161.82,161.82,0,0,1,264.25,256Z" transform="translate(-182.49 -63.87)" />
                <path className={`${pants}-2`} d="M223.56,250.93c-.15,3.86-.33,7.7-.4,11.53-.17,7.46-.17,14.92-.17,22.37l-.14,9.45-.06,6.1h-6.55c-.53-3-1-6-1.44-9q-1.65-11.2-2.81-22.47c-1-9.59-1.75-19.22-2.06-28.85-.13-4.27-.14-8.54-.1-12.81a.4.4,0,0,1,0-.23.36.36,0,0,1,.22-.13,18.28,18.28,0,0,1,7-1,17.4,17.4,0,0,1,3.35.65c.87.24,1.86.36,2.35,1.27a3.43,3.43,0,0,1,.3,1.17A138.73,138.73,0,0,1,223.56,250.93Z" transform="translate(-182.49 -63.87)" />
                <path className={`${pants}-2`} d="M257.24,279.28c-.24,1.48-.46,3-.63,4.45-.4,3.43-.51,6.88-.81,10.31l-.54,6.26H247c-.06-3.22-.1-6.44-.15-9.66-.16-11.38-.31-22.77-.33-34.15,0-7,.42-13.91.09-20.86v-.17q-.1-2.13-.27-4.26c-.07-.93-.65-2.58,0-3.35a6.77,6.77,0,0,1,1.15-.91,11.44,11.44,0,0,1,4-1.78,9.77,9.77,0,0,1,5.38.4c1.36.49,3.24,1.27,3.93,2.61a5.37,5.37,0,0,1,.4,1.39c2,10.91.92,22.37-.84,33.25C259.44,268.36,258.15,273.8,257.24,279.28Z" transform="translate(-182.49 -63.87)" />
                <g>
                  <path className={`${shoes}-2`} d="M211.64,317.41s-1.38,3-.22,4.3c0,0,3.22,2.45,12.18,1.49,0,0,7.21-.26,4.63-5.61Z" transform="translate(-182.49 -63.87)" />
                  <path className={`${shoes}-1`} d="M211.64,317.41l4.27-12.82a3.38,3.38,0,0,1,3.24-2.55H225s2.48,0,2.79,3.72l.5,14s-1.05,1.52-6.43,1.6a45.55,45.55,0,0,1-6.4-.23S210.32,320.75,211.64,317.41Z" transform="translate(-182.49 -63.87)" />
                  <path className={`${shoes}-2`} d="M218.54,305a1.76,1.76,0,0,0,.57-.15,6.22,6.22,0,0,1,3-.06c.44.07,2.14.65,2.08-.16,0-.2-.27-.33-.51-.4a9.51,9.51,0,0,0-4.65-.18c-.38.09-1.25.32-1.34.7S218.15,305,218.54,305Z" transform="translate(-182.49 -63.87)" />
                  <path className={`${shoes}-2`} d="M217.89,307.51a2.9,2.9,0,0,0,.71-.15,10,10,0,0,1,3.82-.06c.55.08,2.69.65,2.61-.15,0-.21-.34-.33-.64-.41a14.55,14.55,0,0,0-5.84-.17c-.49.08-1.59.32-1.69.7S217.36,307.6,217.89,307.51Z" transform="translate(-182.49 -63.87)" />
                  <path className={`${shoes}-2`} d="M216.93,310a5,5,0,0,0,.94-.14,16.76,16.76,0,0,1,5,0c.74.09,3.54.71,3.46-.1,0-.2-.45-.33-.84-.42a25.32,25.32,0,0,0-7.72-.3c-.64.07-2.1.28-2.24.66S216.23,310,216.93,310Z" transform="translate(-182.49 -63.87)" />
                </g>
                <g>
                  <path className={`${shoes}-2`} d="M262.82,317.41s1.38,3,.22,4.3c0,0-3.22,2.45-12.18,1.49,0,0-7.21-.26-4.63-5.61Z" transform="translate(-182.49 -63.87)" />
                  <path className={`${shoes}-1`} d="M262.82,317.41l-4.27-12.82a3.37,3.37,0,0,0-3.21-2.6h-5.82s-2.48,0-2.79,3.72l-.5,14s1,1.52,6.43,1.6a45.66,45.66,0,0,0,6.4-.23S264.15,320.75,262.82,317.41Z" transform="translate(-182.49 -63.87)" />
                  <path className={`${shoes}-2`} d="M255.92,305a1.76,1.76,0,0,1-.57-.15,6.22,6.22,0,0,0-3-.06c-.45.07-2.14.65-2.08-.16,0-.2.27-.33.51-.4a9.47,9.47,0,0,1,4.64-.18c.39.09,1.26.32,1.35.7S256.34,305,255.92,305Z" transform="translate(-182.49 -63.87)" />
                  <path className={`${shoes}-2`} d="M256.57,307.51a2.9,2.9,0,0,1-.71-.15,10,10,0,0,0-3.82-.06c-.55.08-2.69.65-2.61-.15,0-.21.34-.33.64-.41a14.54,14.54,0,0,1,5.84-.17c.49.08,1.59.32,1.69.7S257.15,307.6,256.57,307.51Z" transform="translate(-182.49 -63.87)" />
                  <path className={`${shoes}-2`} d="M257.53,310a5,5,0,0,1-.94-.14,16.76,16.76,0,0,0-5,0c-.74.09-3.55.71-3.46-.1,0-.2.45-.33.83-.42a25.37,25.37,0,0,1,7.73-.3c.64.07,2.1.28,2.24.66S258.23,310,257.53,310Z" transform="translate(-182.49 -63.87)" />
                </g>
              </g>
            </g>
          </g>
          <g id={headAnimation}>
            <circle className={eyesAnimation} cx="46.78" cy="28.4" r="1.91" />
            <circle className={eyesAnimation} cx="59.57" cy="28.4" r="1.91" />
            <ellipse className={mouthAnimation} cx="53.18" cy="43.56" rx="4.76" ry="0.43" />
          </g>
        </g>
      </svg>
      <Laptop className={classes.laptop} logo={logo} />
    </div>
  )
}

export default Person;

Person.defaultProps = {
  chair: 'chair1',
  hair: 'hair1',
  skin: 'skin1',
  shirt: 'shirt1',
  pants: 'pants1',
  shoes: 'shoes1',
  head: 'head1',
  logo: 'python',
  speech: 'python',
  speechPosition: 'left',
  headAnimation: 'head1',
  eyesAnimation: 'eyes1',
  mouthAnimation: 'mouth1'
}